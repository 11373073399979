import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { Account } from '../../helpers/GlobalInterfaces';
import AuthService from '../../helpers/AuthService';
import MaleAvatar from '../../assets/img/avatar/male.jpg';
import FemaleAvatar from '../../assets/img/avatar/female.jpg';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import Avatar from '../../components/Avatar';
import { administratorsMenu } from '../../menu';

const User = () => {
	const [userData, setUserData] = useState<Account>();

	const userDataRaw = AuthService().getUserData();

	useEffect(() => {
		if (userDataRaw !== null) {
			setUserData(JSON.parse(userDataRaw));
		}
	}, [userDataRaw]);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	return (
		<>
			{userData && userData.id && (
				<div
					className={classNames('user', { open: collapseStatus })}
					role='presentation'
					onClick={() => setCollapseStatus(!collapseStatus)}>
					<div className='user-avatar'>
						{userData.id && userData.passportPhoto ? (
							<Avatar src={userData.passportPhoto} size={128} />
						) : (
							<Avatar
								src={
									userData.id > 0 && userData.gender?.toLowerCase() === 'female'
										? FemaleAvatar
										: MaleAvatar
								}
								size={128}
							/>
						)}
					</div>
					<div className='user-info'>
						<div className='user-name'>
							{userData.id > 0 &&
								`${userData.firstname.toUpperCase()} ${userData.firstname.toUpperCase()}`}
							{userData.id > 0 && userData.email_verified_at !== null ? (
								<Icon icon='Verified' className='ms-1' color='info' />
							) : (
								<Icon icon='ErrorOutline' className='ms-1' color='danger' />
							)}
						</div>
						<div className='user-sub-title'>{userData.roles[0].name.toUpperCase()}</div>
					</div>
				</div>
			)}
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() => navigate(administratorsMenu.profile.path)}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => navigate(administratorsMenu.profile.path)}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Profile')}</span>
								</span>
							</span>
						</div>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus ? t('menu:DarkMode') : t('menu:LightMode')}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => AuthService().logoutUser()}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>{t('menu:Logout')}</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
