export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	confirmLogin: {
		id: 'confirmLogin',
		text: 'Please Verify Your account',
		path: '/login/secured/2fa',
		hide: true,
	},
	profile: {
		id: 'profile',
		text: 'My Profile',
		path: '/profile',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forget-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/reset-verification',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		permission: '',
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users',
		icon: 'Person',
		permission: 'view users',
		subMenu: null,
	},
	transactions: {
		id: 'transactions',
		text: 'Transactions',
		path: '/transactions',
		icon: 'ShowChart',
		permission: 'view transactions',
		subMenu: null,
	},
	wallets: {
		id: 'wallets',
		text: 'Wallets',
		path: '/wallets',
		icon: 'BubbleChart',
		permission: 'view wallets',
	},
	kycRequests: {
		id: 'kycRequests',
		text: 'KYC Requests',
		path: '/kyc-requests',
		icon: 'AdUnits',
		permission: 'view kyc requests',
	},
	activityLogs: {
		id: 'activityLogs',
		text: 'Activity Logs',
		path: '/activity-logs',
		icon: 'Psychology',
		permission: 'view logs',
	},
};

export const savingsMenu = {
	savings: {
		id: 'savings',
		text: 'Savings',
		permission: 'view savings',
		icon: 'FormatAlignRight',
	},
	targetPackage: {
		id: 'targetPackage',
		text: 'Target Package',
		path: '/savings/target-package',
		permission: 'view investment packages',
		icon: 'StackedLineChart',
	},
	safelock: {
		id: 'safelocks',
		text: 'Safelock',
		path: '/savings/safelock',
		permission: 'view savings',
		icon: 'FormatStrikethrough',
	},
	stash: {
		id: 'stash',
		text: 'Stash',
		path: '/savings/stash',
		permission: 'view savings',
		icon: 'FormatStrikethrough',
	},
	target: {
		id: 'targets',
		text: 'Target Investments',
		path: '/savings/targets',
		permission: 'view savings',
		icon: 'PanoramaFishEye',
	},
	fixedInvestments: {
		id: 'fixedInvestments',
		text: 'Fixed Investments',
		path: '/savings/fixed-investments',
		permission: 'view fixed deposits',
		icon: 'GpsFixed',
	},
	group: {
		id: 'group-savings',
		text: 'Group Savings',
		path: '/savings/groups',
		permission: 'view groups',
		icon: 'FormatStrikethrough',
	},
};

export const investmentsMenu = {
	investmentsGroup: {
		id: 'investmentsGroup',
		text: 'Investments',
		permission: '',
		icon: 'Extension',
	},
	package: {
		id: 'investmentPackage',
		text: 'Packages',
		path: '/investments/packages',
		icon: 'StackedLineChart',
		permission: 'view investment packages',
	},
	investments: {
		id: 'investments',
		text: 'Investments',
		path: '/investments/investments',
		icon: 'WaterfallChart',
		permission: 'view investments',
	},
};

export const configurationsMenu = {
	configurationsP: {
		id: 'configurationsP',
		text: 'Configurations',
		permission: '',
		icon: 'Extension',
	},
	systemApiKeys: {
		id: 'systemApiKeys',
		text: 'System Api Keys',
		path: '/configurations/system-api-keys',
		icon: 'VpnKey',
		permission: 'view api keys',
	},
	currencies: {
		id: 'currencies',
		text: 'Currencies',
		path: '/configurations/currencies',
		icon: 'AttachMoney',
		permission: 'view currencies',
	},
	identityTypes: {
		id: 'identityTypes',
		text: 'Identity Types',
		path: '/configurations/identity-types',
		icon: 'WaterDrop',
		permission: 'view identity types',
	},
	proofOfAddressType: {
		id: 'proofOfAddressType',
		text: 'Proof Of Address Type',
		path: '/configurations/proof-of-address-type',
		icon: 'AddLocationAlt',
		permission: 'view proof of address types',
	},
	fixedInvestmentTenure: {
		id: 'fixedInvestmentTenure',
		text: 'Fixed Investment Tenure',
		path: '/configurations/fixed-investment-tenures',
		icon: 'AddRoad',
		permission: 'view fixed deposit tenures',
	},
	frequencies: {
		id: 'frequencies',
		text: 'Savings Frequencies',
		path: '/configurations/frequencies',
		icon: 'AccessTime',
		permission: 'view frequencies',
	},
	kycLevels: {
		id: 'kycLevels',
		text: 'KYC Levels',
		path: '/configurations/kyc-levels',
		icon: 'AccountTree',
		permission: 'view kyc levels',
	},
	configurations: {
		id: 'configurations',
		text: 'Configurations',
		path: '/configurations/configurations',
		icon: 'AdminPanelSettings',
		permission: 'view configurations',
	},
	textConfigs: {
		id: 'textConfigs',
		text: 'Policies',
		path: '/configurations/text-configs',
		icon: 'Policy',
		permission: 'view text configs',
	},
	faqGroup: {
		id: 'faqGroup',
		text: 'FAQ Groups',
		path: '/configurations/faq-groups',
		icon: 'Queue',
		permission: 'view faq groups',
	},
	faqs: {
		id: 'faqs',
		text: 'FAQs',
		path: '/configurations/faqs',
		icon: 'QuestionAnswer',
		permission: 'view faqs',
	},
};

export const administratorsMenu = {
	management: {
		id: 'management',
		text: 'Management',
		permission: '',
		icon: 'Extension',
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		path: '/administrations/roles',
		icon: 'Login',
		permission: 'view roles',
	},
	staff: {
		id: 'staff',
		text: 'Staff',
		path: '/administrations/staff',
		icon: 'PersonAdd',
		permission: 'view admins',
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/account-settings',
		icon: 'NotificationAdd',
		permission: null,
		hide: true,
	},
	profile: {
		id: 'Profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
		permission: null,
	},
};
