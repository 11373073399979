import React, { lazy } from 'react';
import {
	dashboardMenu,
	authMenu,
	configurationsMenu,
	savingsMenu,
	investmentsMenu,
	administratorsMenu,
} from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/AuthPage')),
	CONFIRM_LOGIN: lazy(() => import('../pages/auth/ConfirmLogin')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/ForgetPage')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/ConfirmReset')),
	RESET_PASSWORD: lazy(() => import('../pages/auth/ResetPassword')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	USERS: lazy(() => import('../pages/dashboard/user/User')),
	SINGLE_USER: lazy(() => import('../pages/dashboard/user/SingleUser')),
	TRANSACTIONS: lazy(() => import('../pages/dashboard/transaction/Transaction')),
	SINGLE_TRANSACTION: lazy(() => import('../pages/dashboard/transaction/SingleTransaction')),
	WALLETS: lazy(() => import('../pages/dashboard/wallet/Wallet')),
	SINGLE_WALLET: lazy(() => import('../pages/dashboard/wallet/SingleWallet')),
	KYC_REQUESTS: lazy(() => import('../pages/dashboard/KycRequests/KycRequest')),
	ACTIVITY_LOGS: lazy(() => import('../pages/dashboard/ActivityLog/ActivityLog')),
};

const CONFIGURATIONS = {
	SYSTEM_API_KEYS: lazy(() => import('../pages/configurations/apikey/SystemApiKey')),
	EDIT_SYSTEM_API_KEYS: lazy(() => import('../pages/configurations/apikey/EditSystemApiKey')),
	CURRENCY: {
		HOME: lazy(() => import('../pages/configurations/currencies/Currency')),
		SINGLE: lazy(() => import('../pages/configurations/currencies/SingleCurrency')),
		EDIT: lazy(() => import('../pages/configurations/currencies/EditCurrency')),
	},
	IDENTITY_TYPE: {
		HOME: lazy(() => import('../pages/configurations/identityType/IdentityType')),
		EDIT: lazy(() => import('../pages/configurations/identityType/EditIdentityType')),
	},
	PROOF_OF_ADDRESS_TYPE: {
		HOME: lazy(() => import('../pages/configurations/proofOfAddressType/ProofOfAddressType')),
		EDIT: lazy(
			() => import('../pages/configurations/proofOfAddressType/EditProofOfAddressType'),
		),
	},
	FIXED_DEPOSIT_TENURE: {
		HOME: lazy(() => import('../pages/configurations/fixedDepositTenure/FixedDepositTenure')),
		SINGLE: lazy(
			() => import('../pages/configurations/fixedDepositTenure/SingleFixedDepositTenure'),
		),
		EDIT: lazy(
			() => import('../pages/configurations/fixedDepositTenure/EditFixedDepositTenure'),
		),
		CREATE: lazy(
			() => import('../pages/configurations/fixedDepositTenure/CreateFixedDepositTenure'),
		),
	},
	FREQUENCY: lazy(() => import('../pages/configurations/frequencies/Frequency')),
	KYC_LEVELS: {
		HOME: lazy(() => import('../pages/configurations/kycLevels/KycLevel')),
		EDIT: lazy(() => import('../pages/configurations/kycLevels/EditKycLevel')),
	},
	CONFIGURATIONS: {
		HOME: lazy(() => import('../pages/configurations/configurations/Configurations')),
		EDIT: lazy(() => import('../pages/configurations/configurations/EditConfiguration')),
	},
	POLICY: {
		HOME: lazy(() => import('../pages/configurations/policy/Policy')),
		EDIT: lazy(() => import('../pages/configurations/policy/EditPolicy')),
	},
	FAQ_GROUP: {
		HOME: lazy(() => import('../pages/configurations/faqGroup/FaqGroup')),
		EDIT: lazy(() => import('../pages/configurations/faqGroup/EditFaqGroup')),
		SINGLE: lazy(() => import('../pages/configurations/faqGroup/SingleFaqGroup')),
	},
	FAQ: {
		HOME: lazy(() => import('../pages/configurations/faq/Faq')),
		EDIT: lazy(() => import('../pages/configurations/faq/EditFaq')),
	},
};

const SAVINGS = {
	TARGET_PACKAGE: {
		HOME: lazy(() => import('../pages/savings/packages/Packages')),
		CREATE: lazy(() => import('../pages/savings/packages/CreatePackage')),
		EDIT: lazy(() => import('../pages/savings/packages/EditPackage')),
		SINGLE: lazy(() => import('../pages/savings/packages/SinglePackage')),
	},
	STASH: {
		HOME: lazy(() => import('../pages/savings/stash/Stashes')),
		SINGLE: lazy(() => import('../pages/savings/stash/SingleStash')),
	},
	SAFELOCK: {
		HOME: lazy(() => import('../pages/savings/safelock/Safelocks')),
		SINGLE: lazy(() => import('../pages/savings/safelock/SingleSafelock')),
	},
	TARGET: {
		HOME: lazy(() => import('../pages/savings/targets/Targets')),
		SINGLE: lazy(() => import('../pages/savings/targets/SingleTarget')),
	},
	FIXED_DEPOSIT: {
		HOME: lazy(() => import('../pages/savings/fixedDeposit/FixedDeposit')),
		SINGLE: lazy(() => import('../pages/savings/fixedDeposit/SingleFixedDeposit')),
	},
	GROUP: {
		HOME: lazy(() => import('../pages/savings/group/Groups')),
		SINGLE: lazy(() => import('../pages/savings/group/SingleGroup')),
		SINGLE_ITEM: lazy(() => import('../pages/savings/group/SingleSaving')),
	},
};

const INVESTMENTS = {
	PACKAGE: {
		HOME: lazy(() => import('../pages/investments/packages/Packages')),
		CREATE: lazy(() => import('../pages/investments/packages/CreatePackage')),
		EDIT: lazy(() => import('../pages/investments/packages/EditPackage')),
		SINGLE: lazy(() => import('../pages/investments/packages/SinglePackage')),
	},
	INVESTMENT: {
		HOME: lazy(() => import('../pages/investments/investments/Investment')),
		SINGLE: lazy(() => import('../pages/investments/investments/SingleInvestment')),
	},
};

const ADMINISTRATIONS = {
	ROLES: lazy(() => import('../pages/management/role/Role')),
	EDIT_ROLE: lazy(() => import('../pages/management/role/EditRole')),
	STAFF: lazy(() => import('../pages/management/staff/Administrator')),
	PROFILE: lazy(() => import('../pages/management/profile/Profile')),
};

const presentation = [
	/**
	 * AUTH
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
	},
	{
		path: authMenu.confirmLogin.path,
		element: <AUTH.CONFIRM_LOGIN />,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET_PASSWORD />,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.users.path,
		element: <LANDING.USERS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.users.path}/:id`,
		element: <LANDING.SINGLE_USER />,
		exact: true,
	},
	{
		path: dashboardMenu.transactions.path,
		element: <LANDING.TRANSACTIONS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.transactions.path}/:id`,
		element: <LANDING.SINGLE_TRANSACTION />,
		exact: true,
	},
	{
		path: dashboardMenu.wallets.path,
		element: <LANDING.WALLETS />,
		exact: true,
	},
	{
		path: `${dashboardMenu.wallets.path}/:id`,
		element: <LANDING.SINGLE_WALLET />,
		exact: true,
	},
	{
		path: dashboardMenu.kycRequests.path,
		element: <LANDING.KYC_REQUESTS />,
		exact: true,
	},
	{
		path: dashboardMenu.activityLogs.path,
		element: <LANDING.ACTIVITY_LOGS />,
		exact: true,
	},
	/**
	 * Configurations
	 */
	{
		path: configurationsMenu.systemApiKeys.path,
		element: <CONFIGURATIONS.SYSTEM_API_KEYS />,
		exact: true,
	},
	{
		path: `${configurationsMenu.systemApiKeys.path}/:id`,
		element: <CONFIGURATIONS.EDIT_SYSTEM_API_KEYS />,
		exact: true,
	},
	{
		path: configurationsMenu.currencies.path,
		element: <CONFIGURATIONS.CURRENCY.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.currencies.path}/:id`,
		element: <CONFIGURATIONS.CURRENCY.SINGLE />,
		exact: true,
	},
	{
		path: `${configurationsMenu.currencies.path}/edit/:id`,
		element: <CONFIGURATIONS.CURRENCY.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.identityTypes.path,
		element: <CONFIGURATIONS.IDENTITY_TYPE.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.identityTypes.path}/edit/:id`,
		element: <CONFIGURATIONS.IDENTITY_TYPE.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.proofOfAddressType.path,
		element: <CONFIGURATIONS.PROOF_OF_ADDRESS_TYPE.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.proofOfAddressType.path}/edit/:id`,
		element: <CONFIGURATIONS.PROOF_OF_ADDRESS_TYPE.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.fixedInvestmentTenure.path,
		element: <CONFIGURATIONS.FIXED_DEPOSIT_TENURE.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.fixedInvestmentTenure.path}/:id`,
		element: <CONFIGURATIONS.FIXED_DEPOSIT_TENURE.SINGLE />,
		exact: true,
	},
	{
		path: `${configurationsMenu.fixedInvestmentTenure.path}/edit/:id`,
		element: <CONFIGURATIONS.FIXED_DEPOSIT_TENURE.EDIT />,
		exact: true,
	},
	{
		path: `${configurationsMenu.fixedInvestmentTenure.path}/create/new`,
		element: <CONFIGURATIONS.FIXED_DEPOSIT_TENURE.CREATE />,
		exact: true,
	},
	{
		path: configurationsMenu.frequencies.path,
		element: <CONFIGURATIONS.FREQUENCY />,
		exact: true,
	},
	{
		path: configurationsMenu.kycLevels.path,
		element: <CONFIGURATIONS.KYC_LEVELS.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.kycLevels.path}/edit/:id`,
		element: <CONFIGURATIONS.KYC_LEVELS.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.configurations.path,
		element: <CONFIGURATIONS.CONFIGURATIONS.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.configurations.path}/edit/:id`,
		element: <CONFIGURATIONS.CONFIGURATIONS.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.textConfigs.path,
		element: <CONFIGURATIONS.POLICY.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.textConfigs.path}/edit/:id`,
		element: <CONFIGURATIONS.POLICY.EDIT />,
		exact: true,
	},
	{
		path: configurationsMenu.faqGroup.path,
		element: <CONFIGURATIONS.FAQ_GROUP.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.faqGroup.path}/edit/:id`,
		element: <CONFIGURATIONS.FAQ_GROUP.EDIT />,
		exact: true,
	},
	{
		path: `${configurationsMenu.faqGroup.path}/:id`,
		element: <CONFIGURATIONS.FAQ_GROUP.SINGLE />,
		exact: true,
	},
	{
		path: configurationsMenu.faqs.path,
		element: <CONFIGURATIONS.FAQ.HOME />,
		exact: true,
	},
	{
		path: `${configurationsMenu.faqs.path}/edit/:id`,
		element: <CONFIGURATIONS.FAQ.EDIT />,
		exact: true,
	},
	/**
	 * Savings
	 */
	{
		path: savingsMenu.targetPackage.path,
		element: <SAVINGS.TARGET_PACKAGE.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.targetPackage.path}/create/new`,
		element: <SAVINGS.TARGET_PACKAGE.CREATE />,
		exact: true,
	},
	{
		path: `${savingsMenu.targetPackage.path}/edit/:id`,
		element: <SAVINGS.TARGET_PACKAGE.EDIT />,
		exact: true,
	},
	{
		path: `${savingsMenu.targetPackage.path}/:id`,
		element: <SAVINGS.TARGET_PACKAGE.SINGLE />,
		exact: true,
	},
	{
		path: savingsMenu.stash.path,
		element: <SAVINGS.STASH.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.stash.path}/:id`,
		element: <SAVINGS.STASH.SINGLE />,
		exact: true,
	},
	{
		path: savingsMenu.safelock.path,
		element: <SAVINGS.SAFELOCK.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.safelock.path}/:id`,
		element: <SAVINGS.SAFELOCK.SINGLE />,
		exact: true,
	},
	{
		path: savingsMenu.target.path,
		element: <SAVINGS.TARGET.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.target.path}/:id`,
		element: <SAVINGS.TARGET.SINGLE />,
		exact: true,
	},
	{
		path: savingsMenu.fixedInvestments.path,
		element: <SAVINGS.FIXED_DEPOSIT.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.fixedInvestments.path}/:id`,
		element: <SAVINGS.FIXED_DEPOSIT.SINGLE />,
		exact: true,
	},
	{
		path: savingsMenu.group.path,
		element: <SAVINGS.GROUP.HOME />,
		exact: true,
	},
	{
		path: `${savingsMenu.group.path}/:id`,
		element: <SAVINGS.GROUP.SINGLE />,
		exact: true,
	},
	{
		path: `${savingsMenu.group.path}/savings/:id`,
		element: <SAVINGS.GROUP.SINGLE_ITEM />,
		exact: true,
	},
	/**
	 * Investments
	 */
	{
		path: investmentsMenu.package.path,
		element: <INVESTMENTS.PACKAGE.HOME />,
		exact: true,
	},
	{
		path: `${investmentsMenu.package.path}/create/new`,
		element: <INVESTMENTS.PACKAGE.CREATE />,
		exact: true,
	},
	{
		path: `${investmentsMenu.package.path}/edit/:id`,
		element: <INVESTMENTS.PACKAGE.EDIT />,
		exact: true,
	},
	{
		path: `${investmentsMenu.package.path}/:id`,
		element: <INVESTMENTS.PACKAGE.SINGLE />,
		exact: true,
	},
	{
		path: investmentsMenu.investments.path,
		element: <INVESTMENTS.INVESTMENT.HOME />,
		exact: true,
	},
	{
		path: `${investmentsMenu.investments.path}/:id`,
		element: <INVESTMENTS.INVESTMENT.SINGLE />,
		exact: true,
	},
	/**
	 * Administration
	 */
	{
		path: administratorsMenu.roles.path,
		element: <ADMINISTRATIONS.ROLES />,
		exact: true,
	},
	{
		path: `${administratorsMenu.roles.path}/edit/:id`,
		element: <ADMINISTRATIONS.EDIT_ROLE />,
		exact: true,
	},
	{
		path: administratorsMenu.staff.path,
		element: <ADMINISTRATIONS.STAFF />,
		exact: true,
	},
	{
		path: `/profile`,
		element: <ADMINISTRATIONS.PROFILE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
